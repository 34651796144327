import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { AutoCompleteOrder, OrderAutocomplete } from './OrderAutocomplete';
type OrderAutocompleteUncontrolledProps = {
  initialOrderID?: number | null;
  onOrderChange: (orderID: number | null) => void;
};
export const OrderAutocompleteUncontrolled = ({
  initialOrderID,
  onOrderChange
}: OrderAutocompleteUncontrolledProps) => {
  const tenantAlias = useTenantAlias();
  const [selectedOrder, setSelectedOrder] = useState<AutoCompleteOrder | null>(null);
  const {
    data: initialOrderData
  } = useQuery({
    ...spaQueries.order.displayData((initialOrderID as number), tenantAlias),
    enabled: !!initialOrderID
  });
  useEffect(() => {
    if (initialOrderData?.order && initialOrderID) {
      const order: AutoCompleteOrder = {
        id: initialOrderData.order.id,
        buyer: {
          id: initialOrderData.order.orderedBy.company.id,
          name: initialOrderData.order.orderedBy.company.name
        },
        orderedBy: initialOrderData.order.orderedBy,
        orderItems: initialOrderData.order.orderItems,
        statusText: initialOrderData.order.statusText
      };
      setSelectedOrder(order);
    }
  }, [initialOrderData, initialOrderID]);
  useEffect(() => {
    onOrderChange(selectedOrder?.id || null);
  }, [selectedOrder, onOrderChange]);
  const handleOrderChange = (order: AutoCompleteOrder | null) => {
    setSelectedOrder(order);
  };
  return <OrderAutocomplete selectedOrder={selectedOrder} setSelectedOrder={handleOrderChange} data-sentry-element="OrderAutocomplete" data-sentry-component="OrderAutocompleteUncontrolled" data-sentry-source-file="OrderAutocompleteUncontrolled.tsx" />;
};