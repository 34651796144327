import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { AutocompleteQuote, QuoteAutocomplete } from './QuoteAutocomplete';
type QuoteAutocompleteUncontrolledProps = {
  initialQuoteID?: number | null;
  onQuoteChange: (quoteID: number | null) => void;
  isDisabled?: boolean;
};
export const QuoteAutocompleteUncontrolled = ({
  initialQuoteID,
  onQuoteChange,
  isDisabled
}: QuoteAutocompleteUncontrolledProps) => {
  const tenantAlias = useTenantAlias();
  const [selectedQuote, setSelectedQuote] = useState<AutocompleteQuote | null>(null);
  const {
    data: initialQuoteData
  } = useQuery({
    ...spaQueries.quote.displayData((initialQuoteID as number), tenantAlias),
    enabled: !!initialQuoteID
  });
  useEffect(() => {
    if (initialQuoteData?.quote && initialQuoteID) {
      const quote: AutocompleteQuote = {
        id: initialQuoteData.quote.id,
        buyer: initialQuoteData.quote.buyer,
        contact: initialQuoteData.contact,
        createdFor: initialQuoteData.quote.createdFor,
        partRequests: initialQuoteData.quote.partRequests || [],
        statusText: initialQuoteData.quote.statusText
      };
      setSelectedQuote(quote);
    }
  }, [initialQuoteData, initialQuoteID]);
  useEffect(() => {
    onQuoteChange(selectedQuote?.id || null);
  }, [selectedQuote, onQuoteChange]);
  const handleQuoteChange = (quote: AutocompleteQuote | null) => {
    setSelectedQuote(quote);
  };
  return <QuoteAutocomplete selectedQuote={selectedQuote} setSelectedQuote={handleQuoteChange} isDisabled={isDisabled} data-sentry-element="QuoteAutocomplete" data-sentry-component="QuoteAutocompleteUncontrolled" data-sentry-source-file="QuoteAutocompleteUncontrolled.tsx" />;
};