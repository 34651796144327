import React from 'react';
import { FormProvider, useForm, Controller, DefaultValues } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import constants from '@/shared/Constants';
import { TASK_PRIORITIES, TASK_STATUSES, TASK_TYPE_ACTION_ITEM, TASK_TYPE_LEAD_TIME_CONFIRMATION, TASK_TYPES, TASK_TYPE_ORDER_UPDATE, TASK_TYPE_CALL, TASK_TYPE_MANUAL_EMAIL } from '@/shared/constants/TaskConstants';
import { BasicUser } from '@/shared/types/controllers/AuthControllerTypes';
import { CompanyAutocompleteUncontrolled } from '@/src/components/Common/Autocomplete/CompanyAutocompleteUncontrolled';
import { ContactAutocompleteUncontrolled } from '@/src/components/Common/Autocomplete/ContactAutocompleteUncontrolled';
import { OrderAutocompleteUncontrolled } from '@/src/components/Common/Autocomplete/OrderAutocompleteUncontrolled';
import { QuoteAutocompleteUncontrolled } from '@/src/components/Common/Autocomplete/QuoteAutocompleteUncontrolled';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { CreateNewCustomerDrawer } from '../../CreateNewCustomerDrawer/CreateNewCustomerDrawer';

// Define the base form schema
const baseFormSchema = z.object({
  title: z.string().trim().min(1, 'Title is required').max(constants.MAX_STRING_LENGTH, `Must be ${constants.MAX_STRING_LENGTH} characters or less`),
  description: z.string().trim().max(3000, 'Description must be 3000 characters or less').optional().default(''),
  status: z.enum(TASK_STATUSES, {
    required_error: 'Status is required',
    invalid_type_error: 'Invalid status'
  }),
  priority: z.enum(TASK_PRIORITIES, {
    required_error: 'Priority is required',
    invalid_type_error: 'Invalid priority'
  }),
  dueDate: z.date({
    required_error: 'Due date is required',
    invalid_type_error: 'Due date must be a valid date'
  }),
  dueTime: z.date(),
  assignedToID: z.number({
    required_error: 'Assignee is required',
    invalid_type_error: 'Assignee must be selected'
  })
});

// Create the contact association schema
const contactAssociationSchema = z.object({
  associationType: z.literal('contact'),
  contactID: z.number({
    required_error: 'Contact is required',
    invalid_type_error: 'Contact must be selected'
  })
});

// Create the company association schema
const companyAssociationSchema = z.object({
  associationType: z.literal('company'),
  companyID: z.number({
    required_error: 'Company is required',
    invalid_type_error: 'Company must be selected'
  })
});

// Create the other association schema
const otherAssociationSchema = z.object({
  associationType: z.literal('other'),
  associatedWith: z.string().min(1, 'Association is required')
});
const QUOTE_TASK_TYPES = ([TASK_TYPE_LEAD_TIME_CONFIRMATION] as const);
export type QuoteTaskType = (typeof QUOTE_TASK_TYPES)[number];
const ORDER_TASK_TYPES = ([TASK_TYPE_ORDER_UPDATE] as const);
export type OrderTaskType = (typeof ORDER_TASK_TYPES)[number];
const OTHER_TASK_TYPES = ([TASK_TYPE_ACTION_ITEM, TASK_TYPE_CALL, TASK_TYPE_MANUAL_EMAIL] as const);
export type OtherTaskType = (typeof OTHER_TASK_TYPES)[number];
const quoteTaskSchema = z.object({
  type: z.enum(QUOTE_TASK_TYPES),
  quoteID: z.number({
    required_error: 'Quote is required',
    invalid_type_error: 'Quote must be selected'
  })
});
const orderTaskSchema = z.object({
  type: z.enum(ORDER_TASK_TYPES),
  orderID: z.number({
    required_error: 'Order is required',
    invalid_type_error: 'Order must be selected'
  })
});
const otherTaskSchema = z.object({
  type: z.enum(OTHER_TASK_TYPES)
});
const taskTypeSchema = z.discriminatedUnion('type', [quoteTaskSchema, orderTaskSchema, otherTaskSchema]);
const associationSchema = z.discriminatedUnion('associationType', [contactAssociationSchema, companyAssociationSchema, otherAssociationSchema]);
export const formSchema = baseFormSchema.and(associationSchema).and(taskTypeSchema);
export type FormData = z.infer<typeof formSchema>;
export type QuoteTask = z.infer<typeof quoteTaskSchema>;
export type OrderTask = z.infer<typeof orderTaskSchema>;
export type OtherTask = z.infer<typeof otherTaskSchema>;
export type ContactAssociation = z.infer<typeof contactAssociationSchema>;
export type CompanyAssociation = z.infer<typeof companyAssociationSchema>;
export type OtherAssociation = z.infer<typeof otherAssociationSchema>;
export type Association = ContactAssociation | CompanyAssociation | OtherAssociation;
export type TaskType = QuoteTask | OrderTask | OtherTask;
export type BaseFormData = z.infer<typeof baseFormSchema>;

// Type guards for task types
export const isQuoteTask = (data: FormData): data is FormData & QuoteTask => {
  return QUOTE_TASK_TYPES.includes((data.type as QuoteTaskType));
};
export const isOrderTask = (data: FormData): data is FormData & OrderTask => {
  return ORDER_TASK_TYPES.includes((data.type as OrderTaskType));
};
export const isOtherTask = (data: FormData): data is FormData & OtherTask => {
  return OTHER_TASK_TYPES.includes((data.type as OtherTaskType));
};

// Type guards for association types
export const isContactAssociation = (data: FormData): data is FormData & ContactAssociation => {
  return data.associationType === 'contact';
};
export const isCompanyAssociation = (data: FormData): data is FormData & CompanyAssociation => {
  return data.associationType === 'company';
};
export const isOtherAssociation = (data: FormData): data is FormData & OtherAssociation => {
  return data.associationType === 'other';
};
type TaskFormProps = {
  initialData?: FormData;
  isEdit?: boolean;
  onSubmit: (data: FormData) => void;
  onCancel: () => void;
  isSubmitting: boolean;
  isLoading?: boolean;
};
export const TaskForm = ({
  initialData,
  isEdit = false,
  onSubmit,
  onCancel,
  isSubmitting,
  isLoading = false
}: TaskFormProps) => {
  const tenantAlias = useTenantAlias();
  const {
    data: assigneesData,
    isLoading: isLoadingAssignees
  } = useQuery({
    ...spaQueries.user.allAssignees(tenantAlias)
  });
  const getDefaultValues = (): DefaultValues<FormData> => {
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
    const tomorrowNoon = new Date(tomorrow.setHours(12, 0, 0, 0));
    return {
      title: '',
      description: '',
      type: TASK_TYPE_ACTION_ITEM,
      dueDate: tomorrowNoon,
      dueTime: tomorrowNoon,
      assignedToID: undefined,
      associationType: 'contact',
      status: TASK_STATUSES[0],
      priority: TASK_PRIORITIES[0]
    };
  };
  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData || getDefaultValues()
  });
  const {
    control,
    handleSubmit,
    formState: {
      errors
    },
    watch
  } = methods;
  const type = watch('type');
  const showQuoteField = QUOTE_TASK_TYPES.includes((type as QuoteTaskType));
  const showOrderField = ORDER_TASK_TYPES.includes((type as OrderTaskType));
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const openAddNewCustomerDrawer = (initialEmail: string) => {
    setActiveDrawer(<CreateNewCustomerDrawer returnToCreateQuote initialEmail={initialEmail} />);
  };
  if (isLoading) {
    return <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      p: 4
    }}>
        <CircularProgress />
      </Box>;
  }
  return <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-component="TaskForm" data-sentry-source-file="TaskForm.tsx">
      <FormProvider {...methods} data-sentry-element="FormProvider" data-sentry-source-file="TaskForm.tsx">
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{
        mt: 2
      }} data-sentry-element="Box" data-sentry-source-file="TaskForm.tsx">
          <Stack spacing={2} data-sentry-element="Stack" data-sentry-source-file="TaskForm.tsx">
            <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="TaskForm.tsx">
              <Box sx={{
              width: '50%'
            }} data-sentry-element="Box" data-sentry-source-file="TaskForm.tsx">
                <Controller name="associationType" control={control} render={({
                field
              }) => <FormControl fullWidth error={!!errors.associationType}>
                      <InputLabel id="association-type-label">
                        Associate with
                      </InputLabel>
                      <Select labelId="association-type-label" label="Associate with" {...field}>
                        <MenuItem value="contact">Contact</MenuItem>
                        <MenuItem value="company">Company</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                      {errors.associationType && <FormHelperText>
                          {errors.associationType.message}
                        </FormHelperText>}
                    </FormControl>} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />
              </Box>
              <Box sx={{
              width: '50%'
            }} data-sentry-element="Box" data-sentry-source-file="TaskForm.tsx">
                {watch('associationType') === 'contact' && <Controller name="contactID" control={control} rules={{
                required: 'Contact is required'
              }} render={({
                field,
                fieldState: {
                  error
                }
              }) => <FormControl fullWidth error={!!error}>
                        <ContactAutocompleteUncontrolled initialContactID={initialData?.associationType === 'contact' ? initialData.contactID : null} onContactChange={contactID => {
                  field.onChange(contactID);
                }} openAddNewCustomerDrawer={openAddNewCustomerDrawer} />
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                      </FormControl>} />}

                {watch('associationType') === 'company' && <Controller name="companyID" control={control} rules={{
                required: 'Company is required'
              }} render={({
                field,
                fieldState: {
                  error
                }
              }) => <FormControl fullWidth error={!!error}>
                        <CompanyAutocompleteUncontrolled initialCompanyID={initialData?.associationType === 'company' ? initialData.companyID : null} onCompanyChange={companyID => {
                  field.onChange(companyID);
                }} />
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                      </FormControl>} />}

                {watch('associationType') === 'other' && <Controller name="associatedWith" control={control} render={({
                field,
                fieldState: {
                  error
                }
              }) => <FormControl fullWidth error={!!error}>
                        <TextField {...field} label="Associated with" placeholder="Enter association" error={!!error} helperText={error?.message} value={field.value || ''} />
                      </FormControl>} />}
              </Box>
            </Stack>

            <Controller name="title" control={control} render={({
            field
          }) => <TextField label="Task title" fullWidth required error={!!errors.title} helperText={errors.title?.message} {...field} />} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />

            <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="TaskForm.tsx">
              <Box sx={{
              flex: 1
            }} data-sentry-element="Box" data-sentry-source-file="TaskForm.tsx">
                <Controller name="type" control={control} render={({
                field
              }) => <FormControl fullWidth error={!!errors.type}>
                      <InputLabel id="task-type-label">Task type</InputLabel>
                      <Select labelId="task-type-label" label="Task type" {...field}>
                        {TASK_TYPES.map(typeOption => <MenuItem key={typeOption} value={typeOption}>
                            {typeOption}
                          </MenuItem>)}
                      </Select>
                      {errors.type && <FormHelperText>{errors.type.message}</FormHelperText>}
                    </FormControl>} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />
              </Box>
              <Box sx={{
              flex: 1
            }} data-sentry-element="Box" data-sentry-source-file="TaskForm.tsx">
                <Controller name="status" control={control} render={({
                field
              }) => <FormControl fullWidth error={!!errors.status}>
                      <InputLabel id="status-label">Status</InputLabel>
                      <Select labelId="status-label" label="Status" {...field}>
                        {TASK_STATUSES.map(statusOption => <MenuItem key={statusOption} value={statusOption}>
                            {statusOption}
                          </MenuItem>)}
                      </Select>
                      {errors.status && <FormHelperText>{errors.status.message}</FormHelperText>}
                    </FormControl>} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />
              </Box>
            </Stack>

            {showQuoteField && <Controller name="quoteID" control={control} render={({
            field,
            fieldState: {
              error
            }
          }) => <FormControl fullWidth error={!!error}>
                    <QuoteAutocompleteUncontrolled initialQuoteID={initialData && isQuoteTask(initialData) ? initialData.quoteID : null} onQuoteChange={quoteID => {
              field.onChange(quoteID);
            }} />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>} />}

            {showOrderField && <Controller name="orderID" control={control} render={({
            field,
            fieldState: {
              error
            }
          }) => <FormControl fullWidth error={!!error}>
                    <OrderAutocompleteUncontrolled initialOrderID={initialData && isOrderTask(initialData) ? initialData.orderID : null} onOrderChange={orderID => {
              field.onChange(orderID);
            }} />
                    {error && <FormHelperText>{error.message}</FormHelperText>}
                  </FormControl>} />}

            <Controller name="assignedToID" control={control} render={({
            field
          }) => <FormControl fullWidth error={!!errors.assignedToID}>
                  <InputLabel id="assigned-to-label">Assigned to</InputLabel>
                  <Select labelId="assigned-to-label" label="Assigned to" required {...field} value={field.value || ''} onChange={e => field.onChange(Number(e.target.value))} disabled={isLoadingAssignees} startAdornment={isLoadingAssignees ? <Box sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 1
            }}>
                          <CircularProgress size={20} />
                        </Box> : null}>
                    {isLoadingAssignees ? <MenuItem disabled>
                        <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}>
                          <CircularProgress size={20} />
                          <Typography variant="bodyMedium">
                            Loading assignees...
                          </Typography>
                        </Box>
                      </MenuItem> : assigneesData?.users?.map((user: BasicUser) => <MenuItem key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </MenuItem>)}
                  </Select>
                  {errors.assignedToID && <FormHelperText>
                      {errors.assignedToID.message}
                    </FormHelperText>}
                </FormControl>} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />

            <Stack direction="row" spacing={2} data-sentry-element="Stack" data-sentry-source-file="TaskForm.tsx">
              <Stack direction="row" spacing={2} sx={{
              flex: 1
            }} data-sentry-element="Stack" data-sentry-source-file="TaskForm.tsx">
                <Controller name="dueDate" control={control} render={({
                field
              }) => <FormControl fullWidth error={!!errors.dueDate}>
                      <DatePicker label="Due date" value={field.value} onChange={date => field.onChange(date)} slotProps={{
                  textField: {
                    error: !!errors.dueDate,
                    helperText: errors.dueDate?.message
                  }
                }} />
                    </FormControl>} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />
                <Controller name="dueTime" control={control} render={({
                field
              }) => <TimePicker label="Time" value={field.value} onChange={time => field.onChange(time)} sx={{
                width: '100%'
              }} />} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />
              </Stack>

              <Box sx={{
              flex: 1
            }} data-sentry-element="Box" data-sentry-source-file="TaskForm.tsx">
                <Controller name="priority" control={control} render={({
                field
              }) => <FormControl fullWidth error={!!errors.priority}>
                      <InputLabel id="priority-label">Priority</InputLabel>
                      <Select labelId="priority-label" label="Priority" {...field}>
                        {TASK_PRIORITIES.map(priorityOption => <MenuItem key={priorityOption} value={priorityOption}>
                            {priorityOption}
                          </MenuItem>)}
                      </Select>
                      {errors.priority && <FormHelperText>
                          {errors.priority.message}
                        </FormHelperText>}
                    </FormControl>} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />
              </Box>
            </Stack>

            <Controller name="description" control={control} render={({
            field
          }) => <TextField label="Description" fullWidth multiline rows={4} error={!!errors.description} helperText={errors.description?.message || `${field.value?.length || 0}/3000 characters`} {...field} />} data-sentry-element="Controller" data-sentry-source-file="TaskForm.tsx" />

            <Stack direction="row" justifyContent="flex-end" spacing={2} mt={4} data-sentry-element="Stack" data-sentry-source-file="TaskForm.tsx">
              <Button variant="outlined" onClick={onCancel} sx={{
              width: 200
            }} data-sentry-element="Button" data-sentry-source-file="TaskForm.tsx">
                Cancel
              </Button>
              <Button variant="contained" type="submit" sx={{
              width: 200
            }} loading={isSubmitting || isLoadingAssignees} data-sentry-element="Button" data-sentry-source-file="TaskForm.tsx">
                {isEdit ? 'Update Task' : 'Create Task'}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </FormProvider>
    </LocalizationProvider>;
};