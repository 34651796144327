import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { AutoCompleteContact, ContactAutocomplete } from './ContactAutocomplete';
type ContactAutocompleteUncontrolledProps = {
  initialContactID?: number | null;
  onContactChange: (contactID: number | null) => void;
  openAddNewCustomerDrawer: (initialEmail: string) => void;
};
export const ContactAutocompleteUncontrolled = ({
  initialContactID,
  onContactChange,
  openAddNewCustomerDrawer
}: ContactAutocompleteUncontrolledProps) => {
  const tenantAlias = useTenantAlias();
  const [selectedContact, setSelectedContact] = useState<AutoCompleteContact | null>(null);
  const {
    data: initialContactData
  } = useQuery({
    ...spaQueries.contact.findOne(tenantAlias, (initialContactID as number)),
    enabled: !!initialContactID
  });
  useEffect(() => {
    if (initialContactData?.contact && initialContactID) {
      const contact = {
        id: initialContactData.contact.id,
        firstName: initialContactData.contact.firstName,
        lastName: initialContactData.contact.lastName,
        companyName: initialContactData.contact.companyName,
        email: initialContactData.contact.email
      };
      setSelectedContact(contact);
    }
  }, [initialContactData, initialContactID]);
  useEffect(() => {
    onContactChange(selectedContact?.id || null);
  }, [selectedContact, onContactChange]);
  const handleContactChange = (contact: AutoCompleteContact | null) => {
    setSelectedContact(contact);
  };
  return <ContactAutocomplete selectedContact={selectedContact} setSelectedContact={handleContactChange} openAddNewCustomerDrawer={openAddNewCustomerDrawer} data-sentry-element="ContactAutocomplete" data-sentry-component="ContactAutocompleteUncontrolled" data-sentry-source-file="ContactAutocompleteUncontrolled.tsx" />;
};