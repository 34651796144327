import { Box, SxProps, Theme } from '@mui/material';
type CircleProps = {
  children: React.ReactNode;
  size?: number;
  sx?: SxProps<Theme>;
};
export const Circle = ({
  children,
  size = 55,
  sx = {}
}: CircleProps) => {
  return <Box sx={{
    width: size,
    height: size,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...sx
  }} data-sentry-element="Box" data-sentry-component="Circle" data-sentry-source-file="Circle.tsx">
      {children}
    </Box>;
};