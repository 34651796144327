import { useQuery } from '@tanstack/react-query';
import { range } from 'lodash';
import { Autocomplete, Box, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { formatCustomPartNumberWithRevision } from '@/shared/models/CustomPartRevisionHelper';
import { OrderAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { isString } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { StatusLabel } from '../../Order/OrderListPage/StatusLabel/StatusLabel';
const CHARACTER_THRESHOLD = 1;
export type AutoCompleteOrder = OrderAutocompleteResponse['orders'][0];
type OrderAutocompleteProps = {
  selectedOrder: AutoCompleteOrder | null;
  setSelectedOrder: (order: AutoCompleteOrder | null) => void;
};
export const OrderAutocomplete = ({
  selectedOrder,
  setSelectedOrder
}: OrderAutocompleteProps) => {
  const tenantAlias = useTenantAlias();
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('');
  const enabled = debouncedQuery.trim().length >= CHARACTER_THRESHOLD;
  const {
    data: orders = [],
    isLoading
  } = useQuery({
    ...spaQueries.search.orderAutocomplete({
      query: debouncedQuery
    }, tenantAlias),
    enabled,
    select: data => data.orders
  });
  return <Autocomplete inputValue={query} onInputChange={(_event, newInputValue) => {
    if (newInputValue.trim().length === 0) {
      setSelectedOrder(null);
    }
    setQuery(newInputValue);
  }} onChange={(_event, newValue) => {
    if (isOrder(newValue)) {
      setSelectedOrder(newValue);
    }
  }} value={selectedOrder} isOptionEqualToValue={(option, value) => {
    return option?.id === value?.id;
  }} multiple={false} options={orders} filterOptions={options => options} renderInput={params => <TextField {...params} label="Select order" />} getOptionLabel={option => isString(option) ? option : getOrderDisplayText(option)} noOptionsText={<NoOptions />} renderOption={(props, option) => {
    const {
      key,
      ...optionProps
    } = props;
    return <Stack direction="row" component="li" key={key + option.id} {...optionProps} sx={[{
      justifyContent: 'center',
      gap: 2,
      px: 2,
      py: 1
    }]}>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.id}
            </Typography>
            <Box sx={{
        flex: 1
      }}>
              <StatusLabel statusText={option.statusText} isVisible={true} orderID={option.id} />
            </Box>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.buyer.name}
            </Typography>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.orderedBy.firstName} {option.orderedBy.lastName}
            </Typography>
            <Typography variant="bodyMedium" sx={{
        flex: 1
      }} noWrap>
              {renderPartNumber(option)}
            </Typography>
          </Stack>;
  }} slots={{
    paper: ({
      children
    }) => {
      if (!enabled) {
        return null;
      }
      return <Paper square sx={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }}>
              {isLoading && enabled ? <LoadingSkeleton /> : <>{children}</>}
            </Paper>;
    }
  }} data-sentry-element="Autocomplete" data-sentry-component="OrderAutocomplete" data-sentry-source-file="OrderAutocomplete.tsx" />;
};
const NoOptions = () => <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-component="NoOptions" data-sentry-source-file="OrderAutocomplete.tsx">
    We couldn&apos;t find matching orders in our database
  </Typography>;
const LoadingSkeleton = () => <>
    {range(3).map((_, i) => <Skeleton key={i} height={20} sx={{
    mx: 2,
    my: 1
  }} />)}
  </>;
export const isOrder = (order: unknown): order is AutoCompleteOrder => {
  return typeof order === 'object' && order !== null && 'object' in order && order.object === 'Order';
};
export const getOrderDisplayText = (order: AutoCompleteOrder) => {
  const partInfo = renderPartNumber(order);
  return `${order.id} - ${order.buyer.name} - ${partInfo}`;
};
const renderPartNumber = (order: AutoCompleteOrder) => {
  const orderItem = order.orderItems[0];
  if (!orderItem) return '-';
  if ('customPartRevision' in orderItem && orderItem.customPartRevision) {
    return formatCustomPartNumberWithRevision(orderItem.customPartRevision.customPart.partNumber, orderItem.customPartRevision.revisionNumber);
  }
  if ('part' in orderItem && orderItem.part) {
    return orderItem.part.partNumber;
  }
  return '-';
};