export const TASK_TYPE_ACTION_ITEM = 'Action Item' as const;
export const TASK_TYPE_CALL = 'Call' as const;
export const TASK_TYPE_MANUAL_EMAIL = 'Manual Email' as const;
export const TASK_TYPE_ORDER_UPDATE = 'Order Update' as const;
export const TASK_TYPE_LEAD_TIME_CONFIRMATION =
  'Lead Time Confirmation' as const;
export const TASK_TYPES = [
  TASK_TYPE_ACTION_ITEM,
  TASK_TYPE_CALL,
  TASK_TYPE_MANUAL_EMAIL,
  TASK_TYPE_ORDER_UPDATE,
  TASK_TYPE_LEAD_TIME_CONFIRMATION,
] as const;
export type TaskTypeType = (typeof TASK_TYPES)[number];

export const TASK_PRIORITY_LOW = 'Low';
export const TASK_PRIORITY_MEDIUM = 'Medium';
export const TASK_PRIORITY_HIGH = 'High';
export const TASK_PRIORITIES = [
  TASK_PRIORITY_LOW,
  TASK_PRIORITY_MEDIUM,
  TASK_PRIORITY_HIGH,
] as const;
export type TaskPriorityType = (typeof TASK_PRIORITIES)[number];

export const TASK_STATUS_IN_PROGRESS = 'In Progress';
export const TASK_STATUS_COMPLETE = 'Complete';
export const TASK_STATUS_SKIPPED = 'Skipped';
export const TASK_STATUSES = [
  TASK_STATUS_IN_PROGRESS,
  TASK_STATUS_COMPLETE,
  TASK_STATUS_SKIPPED,
] as const;
export type TaskStatusType = (typeof TASK_STATUSES)[number];
