import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { AutocompleteCompany, CompanyAutocomplete } from './CompanyAutocomplete';
type CompanyAutocompleteUncontrolledProps = {
  initialCompanyID?: number | null;
  onCompanyChange: (companyID: number | null) => void;
  isDisabled?: boolean;
};
export const CompanyAutocompleteUncontrolled = ({
  initialCompanyID,
  onCompanyChange,
  isDisabled
}: CompanyAutocompleteUncontrolledProps) => {
  const tenantAlias = useTenantAlias();
  const [selectedCompany, setSelectedCompany] = useState<AutocompleteCompany | null>(null);
  const {
    data: initialCompanyData
  } = useQuery({
    ...spaQueries.company.companyInfo(tenantAlias, (initialCompanyID as number)),
    enabled: !!initialCompanyID
  });
  useEffect(() => {
    if (initialCompanyData && initialCompanyID) {
      const company = {
        id: initialCompanyID,
        name: initialCompanyData.company.name,
        logoURL: '',
        user: {
          id: initialCompanyData.users[0].id,
          firstName: initialCompanyData.users[0]?.firstName || '',
          lastName: initialCompanyData.users[0]?.lastName || '',
          email: initialCompanyData.users[0]?.email || ''
        }
      };
      setSelectedCompany(company);
    }
  }, [initialCompanyData, initialCompanyID]);
  useEffect(() => {
    onCompanyChange(selectedCompany?.id || null);
  }, [selectedCompany, onCompanyChange]);
  const handleCompanyChange = (company: AutocompleteCompany | null) => {
    setSelectedCompany(company);
  };
  return <CompanyAutocomplete selectedCompany={selectedCompany} setSelectedCompany={handleCompanyChange} isDisabled={isDisabled} data-sentry-element="CompanyAutocomplete" data-sentry-component="CompanyAutocompleteUncontrolled" data-sentry-source-file="CompanyAutocompleteUncontrolled.tsx" />;
};