// Our suppliers will enter revision numbers in the part number field so appending the revision number
// to the part number is not necessary
export const formatCustomPartNumberWithRevision = (
  customPartPartNumber: string | undefined,
  revisionNumber: number | null | undefined,
) => {
  if (customPartPartNumber || (revisionNumber && customPartPartNumber)) {
    return customPartPartNumber;
  } else {
    return '';
  }
};
