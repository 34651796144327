import React from 'react';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Email from '@mui/icons-material/Email';
import LocalShipping from '@mui/icons-material/LocalShipping';
import Phone from '@mui/icons-material/Phone';
import { SxProps, Theme } from '@mui/material';
import { Circle } from '@qb/frontend/components/Circle';
import { TaskTypeType } from '@/shared/constants/TaskConstants';
import { UserAllAssigneesResponse } from '@/shared/types/controllers/UserControllerTypes';
import { GetListDataResponse } from '@/shared/types/controllers/spa/SPATaskControllerTypes';

// Helper function to get associated entity name
export const getAssociatedEntity = (task: GetListDataResponse['tasks'][number]) => {
  if ('contact' in task) {
    return `${task.contact.firstName} ${task.contact.lastName}`;
  } else if ('company' in task) {
    return task.company.name;
  } else if ('associatedWith' in task) {
    return task.associatedWith;
  }
  return 'None';
};

// Helper function to get associated entity details
export const getAssociatedEntityDetails = (task: GetListDataResponse['tasks'][number]) => {
  if ('contact' in task) {
    return `${task.contact.companyName}`;
  }
  return '';
};
type TaskTypeIconProps = {
  circleSx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
};

// Helper function to get the appropriate icon for each task type
export const getTaskTypeIcon = (type: TaskTypeType, props?: TaskTypeIconProps) => {
  const {
    circleSx = {},
    iconSx = {}
  } = props || {};
  const getIcon = () => {
    const defaultIconSx = {
      color: 'primaryShared.main',
      ...iconSx
    };
    switch (type) {
      case 'Action Item':
        return <AssignmentTurnedInIcon sx={defaultIconSx} titleAccess="Action Item task type" />;
      case 'Call':
        return <Phone sx={defaultIconSx} titleAccess="Call task type" />;
      case 'Manual Email':
        return <Email sx={defaultIconSx} titleAccess="Manual Email task type" />;
      case 'Order Update':
        return <LocalShipping sx={defaultIconSx} titleAccess="Order Update task type" />;
      case 'Lead Time Confirmation':
        return <CalendarToday sx={defaultIconSx} titleAccess="Lead Time Confirmation task type" />;
      default:
        return <AssignmentTurnedInIcon sx={defaultIconSx} titleAccess="Default task type" />;
    }
  };
  return <Circle size={36} sx={{
    bgcolor: 'primaryShared.8p',
    mr: 1.5,
    flexShrink: 0,
    ...circleSx
  }} aria-label={`${type} task icon`} data-sentry-element="Circle" data-sentry-component="getTaskTypeIcon" data-sentry-source-file="taskUtils.tsx">
      {getIcon()}
    </Circle>;
};
export const getAssigneeName = (assigneeId: number, assigneesData?: UserAllAssigneesResponse) => {
  const assignee = assigneesData?.users?.find((user: UserAllAssigneesResponse['users'][number]) => user.id === assigneeId);
  return assignee ? `${assignee.firstName} ${assignee.lastName}` : 'Unknown';
};