import { useQuery } from '@tanstack/react-query';
import { range } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { QuoteStatus } from '@qb/frontend/components/QuoteStatus/QuoteStatus';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { formatCustomPartNumberWithRevision } from '@/shared/models/CustomPartRevisionHelper';
import { QuoteAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { isNotEmpty, isString } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
export type AutocompleteQuote = QuoteAutocompleteResponse['quotes'][0];
type QuoteAutocompleteProps = {
  selectedQuote: AutocompleteQuote | null;
  setSelectedQuote: (quote: AutocompleteQuote | null) => void;
  isDisabled?: boolean;
};
export const QuoteAutocomplete = ({
  selectedQuote,
  setSelectedQuote,
  isDisabled
}: QuoteAutocompleteProps) => {
  const tenantAlias = useTenantAlias();
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('');
  const isEnabled = debouncedQuery.trim().length > 0;
  const quoteAutocompleteRequest = useQuery({
    ...spaQueries.search.quoteAutocomplete({
      query: debouncedQuery
    }, tenantAlias),
    select: res => res.quotes,
    enabled: isEnabled
  });
  return <Autocomplete options={quoteAutocompleteRequest.data ?? []} getOptionLabel={option => isString(option) ? option : `${option.id}`} inputValue={query} onInputChange={(_event, newInputValue) => {
    if (newInputValue.trim().length === 0) {
      setSelectedQuote(null);
    }
    setQuery(newInputValue);
  }} value={selectedQuote} onChange={(_event, newValue) => {
    if (isQuoteOption(newValue)) {
      setSelectedQuote(newValue);
    }
  }} clearOnBlur={true} isOptionEqualToValue={(option, value) => option.id === value.id} filterOptions={options => options} renderInput={params => <TextField {...params} label="Select quote" />} renderOption={(props, option) => {
    const {
      key,
      ...optionProps
    } = props;
    return <Stack key={key + option.id} direction="row" component="li" {...optionProps} sx={[{
      alignItems: 'center',
      gap: 2,
      px: 2,
      py: 1
    }]}>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.id}
            </Typography>
            <Box sx={{
        flex: 1
      }}>
              <QuoteStatus statusText={option.statusText} />
            </Box>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.buyer?.name || ''}
            </Typography>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.contact ? `${option.contact.firstName} ${option.contact.lastName}` : ''}
            </Typography>
            <Typography variant="bodySmall" sx={{
        flex: 1
      }} noWrap>
              {renderPartNumber(option)}
            </Typography>
          </Stack>;
  }} noOptionsText={<NoOptions />} fullWidth disabled={isDisabled} popupIcon={<ExpandMoreIcon sx={{
    pointerEvents: 'none',
    transform: 'none'
  }} />} sx={{
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      pointerEvents: 'none'
    }
  }} slots={{
    paper: ({
      children
    }) => {
      if (!isEnabled) {
        return null;
      }
      return <Paper square sx={{
        maxHeight: 245,
        overflowY: 'auto'
      }}>
              {quoteAutocompleteRequest.isLoading && isEnabled ? <LoadingSkeleton /> : <>{children}</>}
            </Paper>;
    }
  }} data-sentry-element="Autocomplete" data-sentry-component="QuoteAutocomplete" data-sentry-source-file="QuoteAutocomplete.tsx" />;
};
const LoadingSkeleton = () => <>
    {range(3).map((_, i) => <Skeleton key={i} height={20} sx={{
    mx: 2,
    my: 1
  }} />)}
  </>;
const NoOptions = () => <Box sx={{
  p: 2
}} data-sentry-element="Box" data-sentry-component="NoOptions" data-sentry-source-file="QuoteAutocomplete.tsx">
    <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="QuoteAutocomplete.tsx">
      Unfortunately we couldn&apos;t find a matching quote
    </Typography>
  </Box>;
const isQuoteOption = (option: unknown): option is AutocompleteQuote => {
  return typeof option === 'object' && isNotEmpty(option) && 'id' in option;
};
const renderPartNumber = (quote: AutocompleteQuote) => {
  const partRequest = quote.partRequests[0];
  if (!partRequest) return '-';
  if ('customPartRevision' in partRequest && partRequest.customPartRevision) {
    return formatCustomPartNumberWithRevision(partRequest.customPartRevision.customPart.partNumber, partRequest.customPartRevision.revisionNumber);
  }
  if ('part' in partRequest && partRequest.part) {
    return partRequest.part.partNumber;
  }
  return '-';
};