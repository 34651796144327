import { localToUTC } from '@qb/frontend/utils/timezones';
import {
  FormData,
  isContactAssociation,
  isCompanyAssociation,
  isOtherAssociation,
  isQuoteTask,
  isOrderTask,
  isOtherTask,
} from '../TaskForm';

export const useTaskPayload = () => {
  const createPayload = (formData: FormData) => {
    // Combine date and time into a single date object
    const combinedDueDate = new Date(formData.dueDate);
    combinedDueDate.setHours(formData.dueTime.getHours());
    combinedDueDate.setMinutes(formData.dueTime.getMinutes());

    const utcDueDate = localToUTC(combinedDueDate);

    // Create base payload with common fields
    const basePayload = {
      title: formData.title,
      description: formData.description,
      status: formData.status,
      priority: formData.priority,
      dueDate: utcDueDate,
      assignedToID: formData.assignedToID,
    };

    // Add task type-specific fields
    let taskTypePayload;
    if (isQuoteTask(formData)) {
      taskTypePayload = {
        type: formData.type,
        quoteID: formData.quoteID,
      };
    } else if (isOrderTask(formData)) {
      taskTypePayload = {
        type: formData.type,
        orderID: formData.orderID,
      };
    } else if (isOtherTask(formData)) {
      taskTypePayload = {
        type: formData.type,
      };
    } else {
      throw new Error('Invalid task data');
    }

    // Add association-specific fields
    let associationPayload;
    if (isContactAssociation(formData)) {
      associationPayload = {
        contactID: formData.contactID,
      };
    } else if (isCompanyAssociation(formData)) {
      associationPayload = {
        companyID: formData.companyID,
      };
    } else if (isOtherAssociation(formData)) {
      associationPayload = {
        associatedWith: formData.associatedWith,
      };
    } else {
      throw new Error('Invalid association data');
    }

    // Combine all parts into a single payload
    return {
      ...basePayload,
      ...taskTypePayload,
      ...associationPayload,
    };
  };

  return { createPayload };
};
