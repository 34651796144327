import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { StandardDrawerLayout } from '@/src/components/Common/StandardDrawerLayout/StandardDrawerLayout';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
import { FormData, TaskForm } from '../TaskForm';
import { useTaskPayload } from '../hooks/useTaskPayload';
type CreateTaskDrawerProps = {
  onClose?: () => void;
};
export const CreateTaskDrawer = ({
  onClose
}: CreateTaskDrawerProps) => {
  const tenantAlias = useTenantAlias();
  const {
    hideDrawer
  } = useRightDrawerContext();
  const queryClient = useQueryClient();
  const {
    createPayload
  } = useTaskPayload();
  const createTaskMutation = useMutation({
    ...spaQueries.task.create(tenantAlias),
    onSuccess: () => {
      if (onClose) {
        onClose();
      } else {
        hideDrawer();
      }
      queryClient.invalidateQueries({
        queryKey: ['task']
      });
      toast.success('Task created successfully');
    },
    onError: () => {
      toast.error('Failed to create task. Please try again.');
    }
  });
  const handleSubmit = (data: FormData) => {
    createTaskMutation.mutate(createPayload(data));
  };
  return <StandardDrawerLayout title="Create new task" data-sentry-element="StandardDrawerLayout" data-sentry-component="CreateTaskDrawer" data-sentry-source-file="CreateTaskDrawer.tsx">
      <TaskForm isEdit={false} onSubmit={handleSubmit} onCancel={onClose || hideDrawer} isSubmitting={createTaskMutation.isLoading} data-sentry-element="TaskForm" data-sentry-source-file="CreateTaskDrawer.tsx" />
    </StandardDrawerLayout>;
};