/**
 * Timezone utilities
 */
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { DateTimeFormat } from './dateFormatter';

/**
 * @returns The user's timezone (e.g., 'Europe/Berlin')
 */
export const getUserTimezone = (): string => {
  return DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * For API submissions - preserves the intended display time across timezones
 *
 * @param date - Local date
 * @param timezone - Target timezone (defaults to user's local timezone)
 * @returns UTC date for API storage
 */
export const localToUTC = (date: Date, timezone?: string): Date => {
  const tz = timezone || getUserTimezone();
  return fromZonedTime(date, tz);
};

/**
 * For displaying API data in local timezone
 *
 * @param date - UTC date from API
 * @param timezone - Target timezone (defaults to user's local timezone)
 * @returns Date in local timezone
 */
export const utcToLocal = (date: Date | string, timezone?: string): Date => {
  const tz = timezone || getUserTimezone();
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  return toZonedTime(dateObj, tz);
};
