import { useQuery } from '@tanstack/react-query';
import { range } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { CompanyByUserAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { isNotEmpty, isString } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
export type AutocompleteCompany = CompanyByUserAutocompleteResponse['searchResults'][0];
type CompanyAutocompleteProps = {
  selectedCompany: AutocompleteCompany | null;
  setSelectedCompany: (company: AutocompleteCompany | null) => void;
  isDisabled?: boolean;
};
export const CompanyAutocomplete = ({
  selectedCompany,
  setSelectedCompany,
  isDisabled
}: CompanyAutocompleteProps) => {
  const tenantAlias = useTenantAlias();
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('');
  const isEnabled = debouncedQuery.trim().length > 0;
  const companyAutocompleteRequest = useQuery({
    ...spaQueries.search.companyByUserAutocomplete({
      query: debouncedQuery
    }, tenantAlias),
    select: res => res.searchResults,
    enabled: isEnabled
  });
  return <Autocomplete options={companyAutocompleteRequest.data ?? []} getOptionLabel={option => isString(option) ? option : option.name} inputValue={query} onInputChange={(_event, newInputValue) => {
    if (newInputValue.trim().length === 0) {
      setSelectedCompany(null);
    }
    setQuery(newInputValue);
  }} value={selectedCompany} onChange={(_event, newValue) => {
    if (isCompanyOption(newValue)) {
      setSelectedCompany(newValue);
    }
  }} clearOnBlur={true} isOptionEqualToValue={(option, value) => option.name === value.name} filterOptions={options => options} renderInput={params => <TextField {...params} label="Enter name, e-mail or company name" />} renderOption={(props, option) => {
    const {
      key,
      ...optionProps
    } = props;
    return <Stack key={key + option.user.email} direction="row" component="li" {...optionProps} sx={[{
      alignItems: 'flex-start',
      gap: 2,
      px: 2,
      py: 1
    }]}>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.name}
            </Typography>
            <Typography variant="bodySmall" sx={{
        flex: 1
      }} noWrap>
              {option.user.firstName} {option.user.lastName}
            </Typography>
            <Typography variant="bodySmall" sx={{
        flex: 1
      }} noWrap>
              {option.user.email}
            </Typography>
          </Stack>;
  }} noOptionsText={<NoOptions />} fullWidth disabled={isDisabled} popupIcon={<ExpandMoreIcon sx={{
    pointerEvents: 'none',
    transform: 'none'
  }} />} sx={{
    '& .MuiAutocomplete-popupIndicator': {
      transform: 'none',
      pointerEvents: 'none'
    }
  }} slots={{
    paper: ({
      children
    }) => {
      if (!isEnabled) {
        return null;
      }
      return <Paper square sx={{
        maxHeight: 245,
        overflowY: 'auto'
      }}>
              {companyAutocompleteRequest.isLoading && isEnabled ? <LoadingSkeleton /> : <>{children}</>}
            </Paper>;
    }
  }} data-sentry-element="Autocomplete" data-sentry-component="CompanyAutocomplete" data-sentry-source-file="CompanyAutocomplete.tsx" />;
};
const LoadingSkeleton = () => <>
    {range(3).map((_, i) => <Skeleton key={i} height={20} sx={{
    mx: 2,
    my: 1
  }} />)}
  </>;
const NoOptions = () => <Box sx={{
  p: 2
}} data-sentry-element="Box" data-sentry-component="NoOptions" data-sentry-source-file="CompanyAutocomplete.tsx">
    <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-source-file="CompanyAutocomplete.tsx">
      Unfortunately we couldn’t find a matching company
    </Typography>
  </Box>;
const isCompanyOption = (option: unknown): option is AutocompleteCompany => {
  return typeof option === 'object' && isNotEmpty(option) && 'name' in option && 'user' in option;
};